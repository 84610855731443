<template>
 <div>
     <div  v-if="!formularioCompletado  && !mostrarCanvas">
      <form  class="w-100 h-100 p-3 formulario-bp overflow-auto overflow-x-hidden ">
            <div class="d-flex flex-column align-items-center">
          <img src="@/assets/img/bg-plan-1.png" alt="" class="mb-4 animate__animated animate__backInRight">

        <div v-for="(pregunta, index) in preguntas" :key="index" class="mb-3 w-100">
          <button
            type="button"
            :class="['btn animate__animated animate__bounceInLeft', claseBotonPregunta[index]]"
            @click="seleccionarPregunta(index)"
            :disabled="pregunta.respondida || pregunta.seleccionada"      >
            {{ pregunta.texto }}
          </button>
          <div v-if="pregunta.seleccionada" class=" align-items-center">
            <textarea v-model="pregunta.respuesta" class="form-control" placeholder="Ingrese su respuesta aquí."></textarea>
          </div>
        </div>
        <button type="submit" class="btn btn-dark mt-3" @click="enviarFormulario" :disabled="!todasRespondidas">Enviar Respuestas</button>
      </div>
      </form>
    </div>
 <!-- Mostrar este div una vez completado el formulario -->
 <div v-else class="w-100 h-100 p-3 formulario-bp overflow-auto overflow-x-hidden">
  <div class="d-flex flex-column align-items-center">
    <div v-if="formularioCompletado && !mostrarSegundoFormulario" class="text-center d-flex flex-column align-items-center">
      <img src="@/assets/img/bg-plan-1.png" alt="" class="mb-4  animate__animated animate__backInLeft">

    <div class="mb-3 w-100 animate__animated animate__bounceInLeft">

      <h3>Tu participación es importante para nosotros. </h3>
      <h8>Si lo deseas puedes dejarnos tus datos para mantenerte informado y si eliges no, tu opinión sera anonima.</h8>
      </div>
    <!-- Contenido existente omitido para brevedad -->
    <!-- Botón para mostrar el segundo formulario -->
    <div class="row animate__animated animate__backInUp">
    <div class="col text-center">
      <button type="button" class="btn btn-success mt-3" @click="mostrarSegundoForm">Sí</button>
    </div>
    <div class="col text-center">
      <button type="button" class="btn btn-danger mt-3" @click="confirmarEnvio">No</button>
    </div>  
  </div>
  </div>
  </div>
  
  <!-- Div para mostrar el segundo formulario -->
    <div v-if="mostrarSegundoFormulario" class="w-100 h-100 p-3 formulario-bp overflow-auto overflow-x-hidden">
      <!-- Contenido del segundo formulario -->
      <img src="@/assets/img/bg-plan-1.png" alt="" class="mb-4  animate__animated animate__backInLeft">

      <div class="d-flex flex-column align-items-center  animate__animated animate__backInRight">
        <h3>Formulario de contacto</h3>
          <div :class="{'mb-3 w-100': true, 'has-danger': errores.nombreCompleto}" >
            <div class="form-floating">
              <input type="text" class="form-control" id="nombreCompleto" placeholder="" v-model="nombreCompleto" >

              <label for="nombreCompleto">Nombre Completo</label>
            </div>
            <div class="invalid-feedback" v-if="errores.nombreCompleto">{{ errores.nombreCompleto }}</div>
          </div>
          <div :class="{'mb-3 w-100': true, 'has-danger': errores.email}">
            <div class="form-floating">
              <input type="email" class="form-control" id="email" placeholder=""  v-model="email" >

              <label for="email">Correo Electrónico</label>
            </div>
            <div class="invalid-feedback" v-if="errores.email">{{ errores.email }}</div>
          </div>

          <div :class="{'mb-3 w-100': true, 'has-danger': errores.telf}">
            <div class="form-floating">
              <input type="number" class="form-control" id="telf" placeholder=""  v-model="telf" >

              <label for="telf">Número Telefonico</label>
            </div>
            <div class="invalid-feedback" v-if="errores.telf">{{ errores.telf }}</div>
          </div>

          <p class="bs-component">

              <button type="submit" class="btn btn-dark " @click="confirmarEnvioDatos">Registrar Respuesta y Volver <i class="zmdi zmdi-arrow-right"></i></button>
              </p>
        </div>
    </div>
    <div v-if="mostrarCanvas" class="canvas-container w-100 h-100  formulario-bp overflow-auto overflow-x-hidden">
    <canvas ref="canvas" width="332" height="568"></canvas>
    <canvas ref="canvas2" width="1080" height="1920" v-show="mostrarCanvasOculto" ></canvas>
    <div class="row animate__animated animate__backInUp">
      <div class="col text-center">
        <button type="button" class="btn btn-primary mt-3" @click="volverInicio">Participar</button>
      </div>
      <div class="col text-center">
        <button type="button" class="btn btn-success mt-3" @click="descargarImagenOculta">Descargar</button>
      </div>  
    </div>
    </div>
    </div>
    
    
    
  </div>


</template>
<style scoped>
.canvas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Asegúrate de que el contenedor sea al menos tan alto como la ventana */
  justify-content: center;
  /* Añade más estilos según sea necesario para posicionar correctamente el contenedor */
}
</style>

<script>
import bgImage from '@/assets/img/soy_2.png'; // Asegúrate de importar correctamente la imagen
import bgImageO from '@/assets/img/soy.png'; // Asegúrate de importar correctamente la imagen


export default {
  props: ['datosFormulario'],
  mounted() {
    //console.log('Datos recibidos en FormularioSecundario:', this.datosFormulario);
  },
  data() {
    return {
      mostrarCanvas: false,
      mostrarCanvasOculto:false,
      preguntas: [
        { texto: "¿Cuál consideras que sea el problema principal para recuperar Acacías?", seleccionada: false, respondida: false, respuesta: "" },
        { texto: "¿Cuál consideras que es la causa de este problema?", seleccionada: false, respondida: false, respuesta: "" },
        { texto: "Plantea una solución concreta para el problema.", seleccionada: false, respondida: false, respuesta: "" },
        { texto: "¿Cómo te sueñas Acacías en el 2050?", seleccionada: false, respondida: false, respuesta: "" },
      ],
      nombreCompleto: '',
    telf: '',
    email: '',
      formularioCompletado: false, // Nueva variable para controlar la vista
      mostrarSegundoFormulario: false, // Nueva variable para controlar la visualización del segundo formulario
      errores: {}


    };
  },
  methods: {
    seleccionarPregunta(index) {
      this.preguntas.forEach((pregunta, i) => {
        pregunta.seleccionada = i === index;
      });
    },
    guardarRespuesta(index) {
      const pregunta = this.preguntas[index];
      if (pregunta.respuesta.trim() !== "") {
        pregunta.respondida = true;
        pregunta.seleccionada = false;
      }
    },
    mostrarSegundoForm() {
      this.mostrarSegundoFormulario = true; // Cambiar la variable para mostrar el segundo formulario
    },
    enviarFormulario() {

      this.formularioCompletado = true; // Cambia el estado para mostrar el mensaje final

    },
    volverInicio(){
      // Suponiendo que tienes una tienda Vuex llamada store
      window.location.reload();


    },
    validarFormulario() {
      let valido = true;

      if (!this.nombreCompleto) {
        this.errores.nombreCompleto = "El nombre completo es requerido.";
        valido = false;
      }

      if (!this.email) {
        this.errores.email = "El correo electrónico es requerido.";
        valido = false;
      } else if (!this.validarEmail(this.email)) {
        this.errores.email = "El correo electrónico no es válido.";
        valido = false;
      }

      if (!this.telf) {
        this.errores.telf = "El número telefónico es requerido.";
        valido = false;
      } else if (!this.validarTelefono(this.telf)) {
        this.errores.telf = "El número telefónico no es válido.";
        valido = false;
      }

      return valido;
    },
    validarEmail(email) {
  // Se eliminaron los escapes innecesarios en la expresión regular
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
},

validarTelefono(telf) {
  // Se eliminaron los escapes innecesarios en la expresión regular
  // eslint-disable-next-line no-useless-escape
  const re = /^\d{6,}$/; // Ajusta según tu criterio
  return re.test(telf);
},
  
   confirmarEnvio() {
      const apiUrl = 'https://apialcaldia.codebyalx.com/api/demographicdata/store';
      const preguntasRespuestas = this.preguntas.map((pregunta, index) => ({
          indice: index,
          //texto: pregunta.texto,
          respuesta: pregunta.respuesta
        }));
      

      // Combina los datos del prop con los datos locales
      const datosCompletos = {
        ...this.datosFormulario, // Datos del FormularioBP
        preguntas: preguntasRespuestas,
        anonimo:1,
      };

      console.log('Datos recibidos en FormularioSecundario:', datosCompletos);

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datosCompletos),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        // Maneja el éxito de la solicitud aquí, por ejemplo, mostrando un mensaje al usuario
        this.$emit('formularioEnviado'); // Opcional: emitir un evento si necesitas realizar una acción en el componente padre
      })
      .catch((error) => {
        console.error('Error:', error);
        // Maneja el error aquí, por ejemplo, mostrando un mensaje de error al usuario
      });
    },
    confirmarEnvioDatos() {
      this.errores = {};
      if (this.validarFormulario()) {
          const apiUrl = 'https://apialcaldia.codebyalx.com/api/demographicdata/store';
          const preguntasRespuestas = this.preguntas.map((pregunta, index) => ({
              indice: index,
              //texto: pregunta.texto,
              respuesta: pregunta.respuesta
            }));
          

          // Combina los datos del prop con los datos locales
          const datosCompletos = {
            ...this.datosFormulario, // Datos del FormularioBP
            preguntas: preguntasRespuestas,
            anonimo:2,
            nombreCompleto: this.nombreCompleto,
            telf: this.telf,
            email: this.email,
          };

          console.log('Datos recibidos en FormularioSecundario:', datosCompletos);

          fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(datosCompletos),
          })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            this.$emit('hide-div');
            // Maneja el éxito de la solicitud aquí, por ejemplo, mostrando un mensaje al usuario
            //this.$emit('formularioEnviado'); // Opcional: emitir un evento si necesitas realizar una acción en el componente padre
            this.formularioCompletado = false; // O considera usar una variable diferente si esta controla otros aspectos
            this.mostrarSegundoFormulario = false;
            this.mostrarCanvas = true;
            //this.mostrarCanvasOculto =true;

            this.$nextTick(() => {
          this.dibujarImagenOculta()
            this.dibujarImagen();
      });
          })
          .catch((error) => {
            console.error('Error:', error);
            // Maneja el error aquí, por ejemplo, mostrando un mensaje de error al usuario
          });
        }
        },
        dibujarImagen() {
      const canvas = this.$refs.canvas;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          ctx.font = 'bold 40px Poppins-Regular';
          ctx.fillStyle = '#183152';
          const palabras = this.nombreCompleto.split(' ');
          const canvasWidth = canvas.width;


          // Verifica si hay dos o más espacios en el nombre completo
if (palabras.length >= 2) {
  // Tu lógica actual para manejar nombres con dos o más palabras
  if (palabras.length > 0) {
    const texto1 = palabras[0];
    const texto1Width = ctx.measureText(texto1).width;
    const x1 = (canvasWidth - texto1Width) / 2; // Calcula la posición x para centrar el texto
    ctx.fillText(texto1, x1 + 10, 190); // Ajusta la posición Y según necesites
  }
  if (palabras.length > 1) {
    const texto2 = palabras[1];
    const texto2Width = ctx.measureText(texto2).width;
    const x2 = (canvasWidth - texto2Width) / 2; // Calcula la posición x para centrar el texto
    ctx.fillText(texto2, x2 + 10, 230); // Ajusta la posición Y según necesites, aquí está 20 píxeles debajo del primero
  }
} else {
  // Maneja el caso donde hay 0 o 1 espacio en el nombre completo
  const texto = this.nombreCompleto; // Usa el nombre completo directamente
  const textoWidth = ctx.measureText(texto).width;
  const x = (canvasWidth - textoWidth) / 2; // Calcula la posición x para centrar el texto
  ctx.fillText(texto, x + 10, 190); // Ajusta la posición Y según necesites, puedes cambiar este valor
}
        };
        img.src = bgImage;
      }
    },
    descargarImagen() {
      const canvas = this.$refs.canvas;
      const link = document.createElement('a');
      link.download = 'imagen_personalizada.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    },
    dibujarImagenOculta() {
      const canvasO = this.$refs.canvas2;
      if (canvasO) {
        const ctxO = canvasO.getContext('2d');
        const imgO = new Image();
        imgO.onload = () => {
          ctxO.drawImage(imgO, 0, 0, canvasO.width, canvasO.height);
          ctxO.font = 'bold 130px Poppins-Regular';
          ctxO.fillStyle = '#183152';
          const palabrasO = this.nombreCompleto.split(' ');
          const canvasWidthO = canvasO.width;


          // Verifica si hay dos o más espacios en el nombre completo
if (palabrasO.length >= 2) {
  // Tu lógica actual para manejar nombres con dos o más palabras
  if (palabrasO.length > 0) {
    const texto1O = palabrasO[0];
    const texto1WidthO = ctxO.measureText(texto1O).width;
    const x1O = (canvasWidthO - texto1WidthO) / 2; // Calcula la posición x para centrar el texto
    ctxO.fillText(texto1O, x1O + 20, 640); // Ajusta la posición Y según necesites
  }
  if (palabrasO.length > 1) {
    const texto2O = palabrasO[1];
    const texto2WidthO = ctxO.measureText(texto2O).width;
    const x2O = (canvasWidthO - texto2WidthO) / 2; // Calcula la posición x para centrar el texto
    ctxO.fillText(texto2O, x2O + 20, 760); // Ajusta la posición Y según necesites, aquí está 20 píxeles debajo del primero
  }
} else {
  // Maneja el caso donde hay 0 o 1 espacio en el nombre completo
  const textoO = this.nombreCompleto; // Usa el nombre completo directamente
  const textoWidthO = ctxO.measureText(textoO).width;
  const xO = (canvasWidthO - textoWidthO) / 2; // Calcula la posición x para centrar el texto
  ctxO.fillText(textoO, xO + 10, 660); // Ajusta la posición Y según necesites, puedes cambiar este valor
}
        };
        imgO.src = bgImageO;
      }
    },
    descargarImagenOculta() {
      const canvasO = this.$refs.canvas2;
      const linkO = document.createElement('a');
      linkO.download = 'soy_ficha_clave.png';
      linkO.href = canvasO.toDataURL('image/png');
      linkO.click();
    },
      },
      computed: {
        todasRespondidas() {
          return this.preguntas.every(pregunta => pregunta.respuesta.trim() !== "");    },
          claseBotonPregunta() {
    return this.preguntas.map(pregunta => {
      return pregunta.respuesta.trim() !== "" ? 'btn-success' : 'btn-light';
    });
  }
      },
    }
</script>