<template>

<div class="wrapper" >
			<div class="inner">
        
				<div  v-if="isVisible" class="image-holder d-flex flex-column align-items-center img-principal"  >
					<img src="@/assets/img/ficha_clave.png" alt="">
				</div>
				
          <FormularioBP v-if="formularioActual === 'FormularioBP'" @formularioEnviado="cambiarFormulario($event)" />
          <FormularioSecundario v-else :datosFormulario="datosDelPrimerFormulario" @formularioEnviado="cambiarFormulario" @reiniciarAnimaciones="reiniciarTodasLasAnimacionesS" @hide-div="hideDiv"/>
			
			</div>
		</div>
 

</template>

<script>
//import { Vue3Lottie } from 'vue3-lottie';

import FormularioBP from './components/FormularioBp.vue';
import FormularioSecundario from './components/FormularioSecundario.vue';

export default {
components: {
  FormularioBP,
  FormularioSecundario
},
data() {
  return {
    formularioActual: 'FormularioBP',
    datosDelPrimerFormulario: null, // Agrega esta línea
    isVisible: true,

  };
},
methods: {
  hideDiv() {
    this.isVisible = !this.isVisible;
    },
  cambiarFormulario(datos) {
  console.log("Evento recibido con datos:", datos); // Agregar para depurar

  if (this.formularioActual === 'FormularioBP' && datos) {
    this.datosDelPrimerFormulario = datos;
  }
  this.formularioActual = this.formularioActual === 'FormularioBP' ? 'FormularioSecundario' : 'FormularioBP';
},
 
}

}
</script>
