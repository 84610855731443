import { createApp } from 'vue'
import App from './App.vue'
import 'animate.css';

import './assets/css/bootstrap.min.css';
import "@/assets/fonts/material-design-iconic-font/css/material-design-iconic-font.min.css";

import "@/assets/css/global.css";
import "./assets/img/favicon/favicon-32x32.png";


createApp(App).mount('#app')
